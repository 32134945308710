import React, { useState } from "react";
import { Tag } from "antd";
const { CheckableTag } = Tag;

function ItemFilter(props) {
	const [tagsData, setTagsData] = useState(props.defaultTagsData);

	const handleChange = (tag) => {
		let clonedTagsData = [...tagsData];
		setTagsData(
			clonedTagsData.map(function (clonedTag, index) {
				let checked = tag.title === clonedTag.title ? true : false;
				return {
					title: clonedTag.title,
					checked: checked,
					value: clonedTag.value,
				};
			})
		);
		props.onTagSelected(tag.value);
	};

	const TagsComponent = () =>
		tagsData.map((tag) => (
			<CheckableTag
				style={{
					fontFamily: "poppins",
				}}
				key={tag.title}
				checked={tag.checked}
				onChange={(checked) => handleChange(tag)}
			>
				{tag.title}
			</CheckableTag>
		));

	return (
		<div
			style={{
				marginTop: "10px",
				fontFamily: "poppins-bold",
			}}
		>
			<span
				style={{
					marginRight: 8 + props.marginRight,
				}}
			>
				{props.title}:
			</span>
			{TagsComponent()}
		</div>
	);
}

export default ItemFilter;
