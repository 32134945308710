import React, { useEffect } from "react";
import { List, Skeleton } from "antd";

function ListLoading() {
	const emptyListData = [
		{ id: "test" },
		{ id: "test" },
		{ id: "test" },
		{ id: "test" },
	];

	return (
		<List
			style={{ overflow: "hidden !important" }}
			itemLayout="vertical"
			size="large"
			dataSource={emptyListData}
			renderItem={(item) => (
				<List.Item loading={true}>
					<Skeleton loading={true} active avatar></Skeleton>
				</List.Item>
			)}
		/>
	);
}

export default ListLoading;
