import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import OrderTable from "../components/order/OrderTable";
import AddOrderButton from "../components/order/AddOrderButton";
import OrderPageMobile from "./OrderPageMobile";
import {
	IsMobileWidth,
	IsWithinThisMonth,
	IsWithinThisWeek,
	IsWithinToday,
} from "../utils/Utils";

const Container = styled.div`
	background: transparent;
	border-radius: 3px;
	margin-top: 50px;
	width: 90%;
`;
const Layout = styled.div`
	display: flex;
	justify-content: center;
	background-color: #f1f4f9;
	min-height: 100vh;
`;

const Bar1 = styled.div`
	display: flex;
	margin-bottom: 15px;
`;

function OrderPage() {
	const [reload, setReload] = useState(false);
	const [orderData, setOrderData] = useState([]);

	const [statusFilterTag, setStatusFilterTag] = useState("all");
	const [timelineFilterTag, setTimelineFilterTag] = useState("all");

	const handleReload = () => {
		setReload((currentIsReload) => !currentIsReload);
	};

	useEffect(() => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		// Update the document title using the browser API
		axios
			.get(process.env.REACT_APP_ORDER_GET_ALL_URL, requestConfig)
			.then((res) => {
				setOrderData(res.data);
			})
			.catch((error) => {
				console.log("fetch get all error - ", error);
			});
	}, [reload]);

	// used for tag filtering
	let isLoading = orderData.length > 0 ? false : true;
	let filteredOrderData = orderData;
	switch (timelineFilterTag) {
		case "today":
			filteredOrderData = filteredOrderData.filter(function (item) {
				return IsWithinToday(item.created_timestamp);
			});
			break;
		case "week":
			filteredOrderData = filteredOrderData.filter(function (item) {
				return IsWithinThisWeek(item.created_timestamp);
			});
			break;
		case "month":
			filteredOrderData = filteredOrderData.filter(function (item) {
				return IsWithinThisMonth(item.created_timestamp);
			});
			break;
		default:
			break;
	}

	switch (statusFilterTag) {
		case "to_pay":
			filteredOrderData = filteredOrderData.filter(function (item) {
				return item.status === "To Pay";
			});
			break;
		case "paid":
			filteredOrderData = filteredOrderData.filter(function (item) {
				return item.status === "Paid";
			});
			break;
		default:
			break;
	}

	let tagOrderCount = {
		total: filteredOrderData.length,
		to_pay: orderData.filter(function (item) {
			return item.status === "To Pay";
		}).length,
		week: orderData.filter(function (item) {
			return IsWithinThisWeek(item.created_timestamp);
		}).length,
		month: orderData.filter(function (item) {
			return IsWithinThisMonth(item.created_timestamp);
		}).length,
	};

	const DesktopComponent = () => {
		return (
			<div>
				<Bar1>
					<AddOrderButton></AddOrderButton>
				</Bar1>
				<OrderTable
					onReload={handleReload}
					orderData={orderData}
				></OrderTable>
			</div>
		);
	};

	if (IsMobileWidth()) {
		return (
			<OrderPageMobile
				isLoading={isLoading}
				tagOrderCount={tagOrderCount}
				onReload={handleReload}
				orderData={filteredOrderData}
				onStatusTagSelected={(tag) => setStatusFilterTag(tag)}
				onTimelineTagSelected={(tag) => setTimelineFilterTag(tag)}
			></OrderPageMobile>
		);
	} else {
		return (
			<Layout>
				<Container>{DesktopComponent()}</Container>
			</Layout>
		);
	}
}

export default OrderPage;
