import React, { useState } from "react";
import axios from "axios";
import { Modal, Select, Card, FloatButton, Tag, Image } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";

import ListLoading from "../components/common/ListLoading";
import SearchBarMobile from "../components/order/SearchBarMobile";
import AddItemModal from "../components/modals/AddItemModal";
import EditItemModal from "../components/modals/EditItemModal";
import ItemFilter from "../components/item/ItemFilter";

function ItemPageMobile(props) {
	const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
	const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false);
	const [itemEdit, setItemEdit] = useState();

	const handleSetRank = (itemId, selectedRank) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: itemId,
			selected_rank: selectedRank,
		};

		axios
			.post(
				process.env.REACT_APP_SET_ITEM_RANK_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {
				console.log(`Item set rank success`);
			})
			.catch((error) => {
				console.log(`Item set rank error - `, error);
			});
	};

	const handleDelete = (itemId) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				id: itemId,
			},
		};

		axios
			.delete(process.env.REACT_APP_DELETE_ITEM_URL, requestConfig)
			.then((res) => {
				console.log(`Item ${itemId} is successfully deleted.`);
				props.onReload();
			})
			.catch((error) => {
				console.log(`Item ${itemId} failed to delete.`, error);
			});
	};

	const RankSelectorComponent = (item) => {
		let availableItemCount = props.itemData.filter(function (item) {
			return item.available === true;
		}).length;
		let rankNumberList = [...Array(availableItemCount).keys()].map(
			function (value, _) {
				return { value: value + 1, label: value + 1 };
			}
		);

		return (
			<div>
				<div style={{ display: "flex", alignItems: "middle" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							marginRight: "5px",
						}}
					>
						Select item rank:
					</div>
					<Select
						labelInValue
						defaultValue={{
							value: 1,
							label: 1,
						}}
						style={{
							width: 60,
						}}
						onSelect={(value) => {
							item.rank = value.value;
						}}
						options={rankNumberList}
					/>
				</div>
				<Tag style={{ marginTop: "10px" }} color="red">
					Please select the rank number eventhough its 1
				</Tag>
			</div>
		);
	};

	const handleSetAvailability = (itemId, available) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: itemId,
			available: available,
		};

		axios
			.post(
				process.env.REACT_APP_SET_ITEM_AVAILABILITY_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {
				console.log(`Item set rank success`);
			})
			.catch((error) => {
				console.log(`Item set rank error - `, error);
			});
	};

	const AvailabilitySelectorComponent = (item) => {
		return (
			<div>
				<div>Do you want to set this item to </div>
				<Tag
					style={{
						fontFamily: "poppins-bold",
						margin: "0px",
					}}
					color={item.available ? "red" : "green"}
				>
					{item.available ? "Not Visible" : "Visible"}
				</Tag>
			</div>
		);
	};

	const handleOnSelect = (value, item) => {
		switch (value) {
			case "set_availability":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: AvailabilitySelectorComponent(item),
					okText: "OK",
					cancelText: "Cancel",
					onOk: () =>
						handleSetAvailability(
							item.id,
							item.available ? false : true
						),
				});
			case "set_rank":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: RankSelectorComponent(item),
					okText: "OK",
					cancelText: "Cancel",
					onOk: () => handleSetRank(item.id, item.rank),
				});
			case "edit":
				setIsEditItemModalOpen(true);
				setItemEdit(item);
				break;
			case "delete":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: "Are you sure you want to delete this order?",
					okText: "OK",
					cancelText: "Cancel",
					onOk: () => handleDelete(item.id),
				});
			default:
				console.log("nothing selected");
				break;
		}
	};

	const ActionComponent = (item) => {
		return (
			<Select
				style={{
					width: 137,
					marginTop: "10px",
				}}
				onSelect={(value) => handleOnSelect(value, item)}
				value={"Action"}
				options={[
					{
						value: "set_availability",
						label: "Set Visibility",
					},
					{
						value: "set_rank",
						label: "Set Rank",
					},
					{
						value: "edit",
						label: "Edit",
					},
					{
						value: "delete",
						label: "Delete",
					},
				]}
			/>
		);
	};

	const ListItem = () =>
		props.itemData.map((item) => {
			let imageUrl = process.env.REACT_APP_LOGO_URL;
			if (item.images.length > 0) {
				imageUrl = item.images[0].img_url;
			}

			return (
				<Card
					key={item.id}
					style={{
						marginTop: 16,
						boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Image
							style={{
								borderRadius: "10px",
								boxShadow:
									"5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
							}}
							height={100}
							width={100}
							src={imageUrl}
						/>
						<div
							style={{
								width: "60%",
								fontSize: "15px",
								fontFamily: "poppins-bold",
								textAlign: "end",
							}}
						>
							<div
								style={{
									fontSize: "15px",
									textAlign: "end",
								}}
							>
								{item.name}
							</div>
							<div
								style={{
									fontSize: "13px",
									color: "#7e85d3",
								}}
							>
								{item.weight} KG
							</div>
							<div
								style={{
									fontSize: "15px",
									color: "#55545a",
								}}
							>
								RM
								{(Math.round(item.price * 100) / 100).toFixed(
									2
								)}
							</div>
							<div>
								<Tag
									style={{ fontFamily: "poppins-bold" }}
									color="blue"
								>
									Rank {item.rank}
								</Tag>
								<Tag
									style={{
										fontFamily: "poppins-bold",
										margin: "0px",
									}}
									color={item.available ? "green" : "red"}
								>
									{item.available ? "Visible" : "Not Visible"}
								</Tag>
							</div>
							{ActionComponent(item)}
						</div>
					</div>
				</Card>
			);
		});

	const ListItemComponent = () => {
		if (props.itemData.length > 0) {
			return ListItem();
		} else {
			return <ListLoading />;
		}
	};

	const SummaryComponent = () => {
		return (
			<div style={{ marginTop: "10px" }}>
				<Tag color="blue" style={{ fontFamily: "poppins-bold" }}>
					Total: {props.tagItemCount.total}
				</Tag>
				<Tag color="green" style={{ fontFamily: "poppins-bold" }}>
					Visible: {props.tagItemCount.available}
				</Tag>
				<Tag color="red" style={{ fontFamily: "poppins-bold" }}>
					Not Visible: {props.tagItemCount.not_available}
				</Tag>
			</div>
		);
	};

	const defaultTagsData = [
		{ title: "All", checked: true, value: "all" },
		{ title: "Visible", checked: false, value: "available" },
		{ title: "Not Visible", checked: false, value: "not_available" },
	];

	const EditItemModalComponent = () => {
		if (isEditItemModalOpen) {
			return (
				<EditItemModal
					item={itemEdit}
					isModalOpen={isEditItemModalOpen}
					onHandleCancel={() => setIsEditItemModalOpen(false)}
					onHandleClose={() => setIsEditItemModalOpen(false)}
					onReload={props.onReload}
				></EditItemModal>
			);
		}
	};

	return (
		<div>
			<SearchBarMobile />
			{SummaryComponent()}
			<ItemFilter
				title={"Categories"}
				marginRight={0}
				defaultTagsData={defaultTagsData}
				onTagSelected={props.onTagSelected}
			/>
			{ListItemComponent()}
			<FloatButton
				icon={<PlusOutlined />}
				type="primary"
				style={{ bottom: 70 }}
				onClick={() => setIsAddItemModalOpen(true)}
			/>
			<AddItemModal
				isModalOpen={isAddItemModalOpen}
				onHandleCancel={() => setIsAddItemModalOpen(false)}
				onHandleClose={() => setIsAddItemModalOpen(false)}
				onReload={props.onReload}
			></AddItemModal>
			{EditItemModalComponent()}
		</div>
	);
}

export default ItemPageMobile;
