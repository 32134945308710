import React, { useState, useEffect } from "react";
import LayoutPage from "./LayoutPage";
import LoginPage from "./LoginPage";
import axios from "axios";
import { Spin } from "antd";
import styled from "styled-components";

const Container = styled.div`
	background: transparent;
	border-radius: 3px;
`;

const Layout = styled.div`
	display: flex;
	justify-content: center;
	background-color: #f1f4f9;
	align-items: center;
	height: 100vh; /* fallback for Js load */
	height: ${window.innerHeight}px;
`;

function LandingPage() {
	const [isLoggedIn, setIsLoggedIn] = useState(null);

	useEffect(() => {
		const token = window.localStorage.getItem("token");
		if (token != null && token.length > 0) {
			const requestConfig = {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};

			axios
				.get(process.env.REACT_APP_LOGIN_GET_STATUS_URL, requestConfig)
				.then((res) => {
					console.log("User is authenticated");
					setIsLoggedIn(true);
				})
				.catch((error) => {
					console.log("User is not authenticated");
					setIsLoggedIn(false);
				});
		} else {
			console.log("User is not authenticated");
			setIsLoggedIn(false);
		}
	}, []);

	const landingComponent = () => {
		switch (isLoggedIn) {
			case null:
				return (
					<Layout>
						<Container>
							<Spin tip="Loading..."></Spin>
						</Container>
					</Layout>
				);
			case true:
				return <LayoutPage />;
			case false:
				return <LoginPage />;
			default:
			// code block
		}
	};

	return <div>{landingComponent()}</div>;
}

export default LandingPage;
