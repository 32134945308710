import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import ItemPage from "./ItemPage";
import OrderPage from "./OrderPage";
import CustomerPage from "./CustomerPage";
import MainPage from "./MainPage";
import LoginPage from "./LoginPage";
import { IsMobileWidth } from "../utils/Utils";
import LayoutPageMobile from "./LayoutPageMobile";

import { Layout, Menu, Breadcrumb } from "antd";
import {
	HomeOutlined,
	TeamOutlined,
	ShoppingCartOutlined,
	ShoppingOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

const Logo = styled.div`
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
`;

function LayoutPage() {
	const DesktopComponent = () => {
		return (
			<Layout style={{ minHeight: "100vh" }}>
				<Sider
					breakpoint="lg"
					collapsedWidth="0"
					onBreakpoint={(broken) => {
						console.log(broken);
					}}
					onCollapse={(collapsed, type) => {
						console.log(collapsed, type);
					}}
				>
					<Logo />
					<Menu
						theme="dark"
						defaultSelectedKeys={["1"]}
						mode="inline"
					>
						<Menu.Item key="1" icon={<HomeOutlined />}>
							<Link to="/" />
							Main
						</Menu.Item>
						<Menu.Item key="3" icon={<ShoppingOutlined />}>
							<Link to="/item" />
							Item
						</Menu.Item>
						<Menu.Item key="4" icon={<ShoppingCartOutlined />}>
							<Link to="/order" />
							Order
						</Menu.Item>
						<Menu.Item key="2" icon={<TeamOutlined />}>
							<Link to="/customer" />
							Customer
						</Menu.Item>
					</Menu>
				</Sider>
				<Layout className="site-layout">
					<Header
						className="site-layout-background"
						style={{ padding: 0, background: "#fff" }}
					></Header>
					<Content style={{ margin: "0 16px" }}>
						<Breadcrumb style={{ margin: "16px 29px" }}>
							<Breadcrumb.Item>User</Breadcrumb.Item>
							<Breadcrumb.Item>Bill</Breadcrumb.Item>
						</Breadcrumb>
						<div
							className="site-layout-background"
							style={{ padding: 24, minHeight: 360 }}
						>
							<Routes>
								<Route path="/" exact element={<MainPage />} />
								<Route
									path="/login"
									exact
									element={<LoginPage />}
								/>
								<Route
									path="/item"
									exact
									element={<ItemPage />}
								/>
								<Route
									path="/customer"
									exact
									element={<CustomerPage />}
								/>
								<Route
									path="/order"
									exact
									element={<OrderPage />}
								/>
							</Routes>
						</div>
					</Content>
					<Footer style={{ textAlign: "center" }}>
						Seafood Admin Portal @2022 created EDAR Solution
					</Footer>
				</Layout>
			</Layout>
		);
	};

	if (IsMobileWidth()) {
		return <LayoutPageMobile />;
	} else {
		return DesktopComponent();
	}
}

export default LayoutPage;
