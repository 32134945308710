export const ExpensesCategoryEnum = {
	1: "BUSINESS",
	2: "WEBSITE",
	3: "TRANSPORTATION",
	4: "ENTERTAINMENT",
	5: "PACKAGING",
	6: "ADVERTISEMENT",
	7: "SALARY",
	8: "HOUSING",
	9: "ASSET",
};

export const ExpensesInnerCategoryEnum = {
	1: { 1: "BUSINESS", 2: "REGISTRATION" },
	2: { 1: "WEBSITE", 2: "CLOUD" },
	3: {
		1: "TRANSPORTATION",
		2: "FUEL",
		3: "DELIVERY",
		4: "RENT",
		5: "TAXI",
		6: "MAINTAINENCE",
	},
	4: { 1: "ENTERTAINMENT" },
	5: { 1: "PACKAGING" },
	6: { 1: "ADVERTISEMENT" },
	7: { 1: "SALARY" },
	8: { 1: "HOUSING", 2: "RENT", 3: "BILLS" },
	9: { 1: "ASSET" },
};

export const ExpensesStatusEnum = {
	1: "SUBMITTED",
	2: "APPROVED",
	3: "CLAIMED",
	4: "REJECTED",
};
