import React, { useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";

import { Table, Space, Button, Select } from "antd";

import ButtonDelete from "./ButtonDelete";
import ButtonPrint from "./ButtonPrint";
import OrderTableExpanded from "./OrderTableExpanded";

function OrderTable(props) {
	const statusMap = new Map();
	statusMap.set("topay", "To Pay");
	statusMap.set("paid", "Paid");

	const handleInvoice = (id, invoice_number) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			responseType: "blob",
			params: {
				id: id,
			},
		};

		axios
			.get(process.env.REACT_APP_INVOICE_URL, requestConfig)
			.then((res) => {
				fileDownload(res.data, `${invoice_number}.pdf`);
			})
			.catch((error) => {
				console.log("fetch get all error - ", error);
			});
	};

	const getByValue = (map, searchValue) => {
		for (let [key, value] of map.entries()) {
			if (value === searchValue) return key;
		}
	};

	const handleOrderStatusChange = (value, id) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: id,
			status: statusMap.get(value),
		};

		axios
			.patch(
				process.env.REACT_APP_ORDER_UPDATE_STATUS_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {})
			.catch((error) => {
				console.log(`Order update error - ${id}`, error);
			});
	};

	const columns = [
		{
			title: "Invoice ID",
			dataIndex: "invoice_number",
			key: "invoice_number",
			width: "10%",
		},
		{ title: "Name", dataIndex: "customer_name", key: "customer_name" },
		{
			title: "Phone Number",
			dataIndex: "phone_number",
			key: "phone_number",
		},
		{
			title: "Delivery",
			dataIndex: "is_delivery",
			key: "is_delivery",
			render: (text, record, index) => {
				return <a>{text ? "Yes" : "No"}</a>;
			},
		},
		{ title: "Total Price", dataIndex: "total_price", key: "total_price" },
		{
			title: "Date",
			dataIndex: "created_timestamp",
			key: "created_timestamp",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text, record, index) => (
				<Select
					defaultValue={getByValue(statusMap, record.status)}
					style={{
						width: 120,
					}}
					onChange={(value) =>
						handleOrderStatusChange(value, record.id)
					}
					options={[
						{
							value: "topay",
							label: "To Pay",
						},
						{
							value: "paid",
							label: "Paid",
						},
					]}
				/>
			),
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						onClick={() =>
							handleInvoice(record.id, record.invoice_number)
						}
					>
						Download
					</Button>
					<ButtonPrint id={record.id} />
					<ButtonDelete
						id={record.id}
						onReload={() => props.onReload()}
					/>
				</Space>
			),
		},
	];

	return (
		<Table
			rowKey="id"
			columns={columns}
			expandable={{
				expandedRowRender: (record, index, indent, expanded) =>
					OrderTableExpanded(record),
			}}
			dataSource={props.orderData}
			scroll={{ x: 300 }}
		/>
	);
}

export default OrderTable;
