import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import CustomerTable from "../components/customer/CustomerTable";
import CustomerPageMobile from "./CustomerPageMobile";

import { IsMobileWidth } from "../utils/Utils";

const Container = styled.div`
	background: transparent;
	border-radius: 3px;
	margin-top: 50px;
	width: 90%;
`;
const Layout = styled.div`
	display: flex;
	justify-content: center;
	background-color: #f1f4f9;
	min-height: 100vh;
`;

function CustomerPage() {
	const [customerData, setCustomerData] = useState([]);

	const [reload, setReload] = useState(0);

	const handleReload = () => {
		setReload(Math.random());
	};

	useEffect(() => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		// Update the document title using the browser API
		axios
			.get(process.env.REACT_APP_CUSTOMERS_URL, requestConfig)
			.then((res) => {
				setCustomerData(res.data);
			})
			.catch((error) => {
				console.log("fetch get all error - ", error);
			});
	}, [reload]);

	const DesktopComponent = () => {
		return (
			<Layout>
				<Container>
					<CustomerTable
						items={customerData}
						onReload={handleReload}
					></CustomerTable>
				</Container>
			</Layout>
		);
	};

	if (IsMobileWidth()) {
		return (
			<CustomerPageMobile
				onReload={handleReload}
				customerData={customerData}
			/>
		);
	} else {
		return DesktopComponent();
	}
}

export default CustomerPage;
