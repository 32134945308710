import React from "react";

import EditCustomerButton from "../customer/EditCustomerButton";
import ButtonDelete from "./ButtonDelete";
import { Table, Space, Button } from "antd";

function ItemTable(props) {
	const handleReload = () => {
		props.onReload();
	};

	console.log(props.items);

	const columns = [
		{ title: "ID", dataIndex: "id", key: "id", width: "10%" },
		{ title: "Name", dataIndex: "name", key: "name" },
		{ title: "Address", dataIndex: "address", key: "address" },
		{
			title: "PhoneNumber",
			dataIndex: "phone_number",
			key: "phone_number",
		},
		{
			title: "Date",
			dataIndex: "created_timestamp",
			key: "created_timestamp",
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text, record, index) => (
				<Space size="middle">
					<EditCustomerButton
						item={record}
						onReload={handleReload}
					></EditCustomerButton>
					<ButtonDelete
						id={record.id}
						onReload={() => props.onReload()}
					/>
				</Space>
			),
		},
	];

	return (
		<Table
			rowKey="order_id"
			columns={columns}
			dataSource={props.items}
			scroll={{ x: 300 }}
		/>
	);
}

export default ItemTable;
