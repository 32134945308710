import React, { useState } from "react";
import { Modal, Button, Form } from "antd";
import axios from "axios";

import ItemForm from "../forms/ItemForm";
import PictureWall from "../pictures/PictureWall";

function EditItemModal(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();

	const handleOK = () => {
		setIsLoading(true);

		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: props.item.id,
			name: form.getFieldValue("name"),
			description: form.getFieldValue("description"),
			price: form.getFieldValue("price"),
			weight: form.getFieldValue("weight"),
			available: form.getFieldValue("available") === "yes" ? true : false,
		};

		axios
			.patch(
				process.env.REACT_APP_PATCH_ITEM_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {
				props.onHandleClose();
				props.onReload();
			})
			.catch((error) => {
				console.log(`Item edit error - ${props.item.id}`, error);
				setIsLoading(false);
			});
	};


	return (
		<Modal
			mask={false}
			title="Edit Item"
			open={props.isModalOpen}
			onOk={handleOK}
			onCancel={() => props.onHandleCancel()}
			width={1000}
			height={300}
			footer={[
				<Button onClick={() => props.onHandleCancel()}>Cancel</Button>,
				<Button
					key="submit"
					type="primary"
					loading={isLoading}
					onClick={handleOK}
				>
					Save
				</Button>,
			]}
			bodyStyle={{ overflowY: "scroll", height: 500 }}
		>
			<ItemForm item={props.item} form={form}></ItemForm>
			<PictureWall item={props.item}></PictureWall>
		</Modal>
	);
}

export default EditItemModal;
