import React, { useState } from "react";
import { Modal, Button, Form } from "antd";
import axios from "axios";

import CustomerForm from "../forms/CustomerForm";

function EditCustomerModal(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();

	const handleOK = () => {
		setIsLoading(true);

		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: props.item.id,
			name: form.getFieldValue("name"),
			email: form.getFieldValue("email"),
			address: form.getFieldValue("address"),
			phone_number: form.getFieldValue("phone_number"),
		};

		axios
			.patch(
				process.env.REACT_APP_CUSTOMER_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {
				props.onHandleClose();
				props.onReload();
			})
			.catch((error) => {
				console.log(`Customer edit error - ${props.item.id}`, error);
			});
	};

	return (
		<Modal
			mask={false}
			title="Edit Item"
			open={props.isModalOpen}
			onOk={handleOK}
			onCancel={() => props.onHandleCancel()}
			width={1000}
			height={300}
			footer={[
				<Button onClick={() => props.onHandleCancel()}>Cancel</Button>,
				<Button
					key="submit"
					type="primary"
					loading={isLoading}
					onClick={handleOK}
				>
					Save
				</Button>,
			]}
			bodyStyle={{ overflowY: "scroll", height: 500 }}
		>
			<CustomerForm item={props.item} form={form}></CustomerForm>
		</Modal>
	);
}

export default EditCustomerModal;
