import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import ItemTable from "../components/item/ItemTable";
import AddItemButton from "../components/item/AddItemButton";
import ItemPageMobile from "./ItemPageMobile";

import { IsMobileWidth } from "../utils/Utils";

const Container = styled.div`
	background: transparent;
	border-radius: 3px;
	margin-top: 50px;
	width: 90%;
`;
const Layout = styled.div`
	display: flex;
	justify-content: center;
	background-color: #f1f4f9;
	min-height: 100vh;
`;

function ItemPage() {
	const [itemData, setItemData] = useState([]);
	const [filterTag, setFilterTag] = useState("all");

	const [reload, setReload] = useState(0);

	const handleReload = () => {
		setReload(Math.random());
	};

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_ITEM_GET_ALL_URL)
			.then((res) => {
				setItemData(res.data);
			})
			.catch((error) => {
				console.log("Get all item error - ", error);
			});
	}, [reload]);

	// used for tag filtering
	let filteredItemData = itemData;
	switch (filterTag) {
		case "available":
			filteredItemData = filteredItemData.filter(function (item) {
				return item.available === true;
			});
			break;
		case "not_available":
			filteredItemData = filteredItemData.filter(function (item) {
				return item.available === false;
			});
			break;
		default:
			break;
	}

	let tagItemCount = {
		total: itemData.length,
		available: itemData.filter(function (item) {
			return item.available === true;
		}).length,
		not_available: itemData.filter(function (item) {
			return item.available === false;
		}).length,
	};

	const DesktopComponent = () => {
		return (
			<Layout>
				<Container>
					<AddItemButton onReload={handleReload}></AddItemButton>
					<ItemTable
						items={filteredItemData}
						onReload={handleReload}
					></ItemTable>
				</Container>
			</Layout>
		);
	};

	if (IsMobileWidth()) {
		return (
			<ItemPageMobile
				onReload={handleReload}
				onTagSelected={(tag) => setFilterTag(tag)}
				itemData={filteredItemData}
				tagItemCount={tagItemCount}
			/>
		);
	} else {
		return DesktopComponent();
	}
}

export default ItemPage;
