import React, { useState } from "react";
import { Form, Input, InputNumber, Divider, Radio } from "antd";

import {
	ExpensesCategoryEnum,
	ExpensesInnerCategoryEnum,
} from "../../constants/Expenses";

function AddExpensesForm(props) {
	const [componentSize, setComponentSize] = useState("default");
	const [selectedCategory, setSelectedCategory] = useState(1);

	const onFormLayoutChange = ({ size }) => {
		setComponentSize(size);
	};

	const CategoryRadioComponent = () => {
		return (
			<Radio.Group
				optionType="button"
				buttonStyle="solid"
				onChange={(e) => setSelectedCategory(e.target.value)}
			>
				{Object.entries(ExpensesCategoryEnum).map(([k, v]) => {
					return <Radio.Button value={k}>{v}</Radio.Button>;
				})}
			</Radio.Group>
		);
	};

	const InnerCategoryRadioComponent = () => {
		return (
			<Radio.Group optionType="button" buttonStyle="solid">
				{Object.entries(
					ExpensesInnerCategoryEnum[selectedCategory]
				).map(([k, v]) => {
					return <Radio.Button value={k}>{v}</Radio.Button>;
				})}
			</Radio.Group>
		);
	};

	return (
		<Form
			labelCol={{
				span: 4,
			}}
			wrapperCol={{
				span: 14,
			}}
			layout="horizontal"
			initialValues={{
				size: componentSize,
				quantity: 1,
				category: 1,
				inner_category: 1,
			}}
			onValuesChange={onFormLayoutChange}
			size={componentSize}
			form={props.form}
		>
			<Form.Item
				label={
					<div style={{ fontFamily: "Poppins", fontSize: "16px" }}>
						Categories
					</div>
				}
				name="category"
			>
				{CategoryRadioComponent()}
			</Form.Item>
			<Form.Item
				label={
					<div style={{ fontFamily: "Poppins", fontSize: "16px" }}>
						Inner Categories
					</div>
				}
				name="inner_category"
			>
				{InnerCategoryRadioComponent()}
			</Form.Item>
			<Form.Item
				label={
					<div style={{ fontFamily: "Poppins", fontSize: "16px" }}>
						Description
					</div>
				}
				name="description"
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				label={
					<div style={{ fontFamily: "Poppins", fontSize: "16px" }}>
						Quantity
					</div>
				}
				name="quantity"
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				label={
					<div style={{ fontFamily: "Poppins", fontSize: "16px" }}>
						Unit Price (RM)
					</div>
				}
				name="price"
			>
				<Input placeholder="0" size="large" />
			</Form.Item>
		</Form>
	);
}

export default AddExpensesForm;
