import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import ExpensesPageMobile from "./ExpensesPageMobile";
import AddExpensesPageMobile from "./AddExpensesPageMobile";

import {
	IsMobileWidth,
	IsWithinToday,
	IsWithinThisMonth,
	IsWithinThisWeek,
} from "../../utils/Utils";

const Container = styled.div`
	background: transparent;
	border-radius: 3px;
	margin-top: 50px;
	width: 90%;
`;
const Layout = styled.div`
	display: flex;
	justify-content: center;
	background-color: #f1f4f9;
	min-height: 100vh;
`;

function ExpensesPage() {
	const [expensesData, setExpensesData] = useState([]);
	const [timelineFilterTag, setTimelineFilterTag] = useState("all");
	const [isMobileAddExpenses, setIsMobileAddExpenses] = useState(false);

	const [reload, setReload] = useState(0);

	const handleReload = () => {
		setReload(Math.random());
	};

	useEffect(() => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		// Update the document title using the browser API
		axios
			.get(process.env.REACT_APP_EXPENSES_URL, requestConfig)
			.then((res) => {
				setExpensesData(res.data);
			})
			.catch((error) => {
				console.log("fetch get all error - ", error);
			});
	}, [reload]);

	// used for tag filtering
	let isLoading = expensesData.length > 0 ? false : true;
	let filteredExpensesData = expensesData;
	switch (timelineFilterTag) {
		case "today":
			filteredExpensesData = filteredExpensesData.filter(function (item) {
				return IsWithinToday(item.created_timestamp);
			});
			break;
		case "week":
			filteredExpensesData = filteredExpensesData.filter(function (item) {
				return IsWithinThisWeek(item.created_timestamp);
			});
			break;
		case "month":
			filteredExpensesData = filteredExpensesData.filter(function (item) {
				return IsWithinThisMonth(item.created_timestamp);
			});
			break;
		default:
			break;
	}

	const getTotalPrice = (expenses) => {
		return (
			Math.round(
				expenses.reduce(
					(acc, obj) => acc + obj.price * obj.quantity,
					0
				) * 100
			) / 100
		);
	};

	let tagExpensesCount = {
		total: getTotalPrice(expensesData),
		week: getTotalPrice(
			expensesData.filter(function (item) {
				return IsWithinThisWeek(item.created_timestamp);
			})
		),
		month: getTotalPrice(
			expensesData.filter(function (item) {
				return IsWithinThisMonth(item.created_timestamp);
			})
		),
	};

	const DesktopComponent = () => {
		return (
			<Layout>
				<Container></Container>
			</Layout>
		);
	};

	console.log(isMobileAddExpenses);

	if (IsMobileWidth()) {
		if (!isMobileAddExpenses) {
			return (
				<ExpensesPageMobile
					isLoading={isLoading}
					onReload={handleReload}
					expensesData={filteredExpensesData}
					tagExpensesCount={tagExpensesCount}
					onTimelineTagSelected={(tag) => setTimelineFilterTag(tag)}
					onMobileAddExpenses={() => setIsMobileAddExpenses(true)}
				/>
			);
		} else {
			return (
				<AddExpensesPageMobile
					onBack={() => setIsMobileAddExpenses(false)}
				/>
			);
		}
	} else {
		return DesktopComponent();
	}
}

export default ExpensesPage;
