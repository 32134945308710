import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Alert, Image } from "antd";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
	background: transparent;
	border-radius: 3px;
`;

const Layout = styled.div`
	display: flex;
	justify-content: center;
	background-color: #f1f4f9;
	align-items: center;
	height: 100vh; /* fallback for Js load */
	height: ${window.innerHeight}px;
`;

const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
`;

const CenteredLayout = styled.div`
	display: flex;
	justify-content: center;
`;

function LoginPage() {
	const [loginInfo, setLoginInfo] = useState("");

	const onFinish = (values) => {
		const requestBody = {
			username: values.username,
			password: values.password,
		};
		axios
			.post(process.env.REACT_APP_LOGIN_URL, requestBody)
			.then((res) => {
				console.log("Login Success");
				window.localStorage.setItem("token", res.data.token);
				window.location.reload();
			})
			.catch((error) => {
				setLoginInfo("Incorrect username or password.");
				console.log("Login Failed");
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};
	return (
		<Layout>
			<Container>
				<ImageContainer>
					<Image
						width={200}
						src={process.env.REACT_APP_LOGO_URL}
					></Image>
				</ImageContainer>
				<Form
					name="basic"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					initialValues={{
						remember: true,
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Form.Item
						label="Username"
						name="username"
						rules={[
							{
								required: true,
								message: "Please input your username!",
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[
							{
								required: true,
								message: "Please input your password!",
							},
						]}
					>
						<Input.Password />
					</Form.Item>
					<CenteredLayout>
						<Form.Item name="remember" valuePropName="checked">
							<Checkbox>Remember me</Checkbox>
						</Form.Item>
						{loginInfo.length > 0 ? (
							<Alert message={loginInfo} type="error" />
						) : null}
					</CenteredLayout>
					<CenteredLayout>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</CenteredLayout>
				</Form>
			</Container>
		</Layout>
	);
}

export default LoginPage;
