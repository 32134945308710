import React from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { NavBar, TabBar } from "antd-mobile";

import ItemPage from "./ItemPage";
import OrderPage from "./OrderPage";
import CustomerPage from "./CustomerPage";
import MainPage from "./MainPage";
import LoginPage from "./LoginPage";
import ExpensesPage from "./expenses/ExpensesPage";

import {
	HomeOutlined,
	TeamOutlined,
	ShoppingCartOutlined,
	ShoppingOutlined,
	DollarCircleOutlined,
	GoldOutlined,
} from "@ant-design/icons";

const App = styled.div`
	height: 100vh; /* fallback for Js load */
	height: ${window.innerHeight}px;
	display: flex;
	flex-direction: column;
`;

const Top = styled.div`
	flex: 0;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Body = styled.div`
	padding: 15px;
	height: 100vh;
	overflow: auto;
	background-color: #eef0f4;
`;

const Bottom = styled.div`
	flex: 0;
	border-top: solid 1px var(--adm-color-border);
`;

function LayoutPageMobile(props) {
	const NavBarComponent = () => {
		const navigate = useNavigate();
		const location = useLocation();
		const { pathname } = location;

		const setRouteActive = (value) => {
			navigate(value);
		};

		const tabs = [
			{
				key: "/",
				title: "Home",
				icon: <HomeOutlined />,
			},
			{
				key: "/item",
				title: "Item",
				icon: <ShoppingOutlined />,
			},
			{
				key: "/order",
				title: "Order",
				icon: <ShoppingCartOutlined />,
			},
			{
				key: "/customer",
				title: "Customer",
				icon: <TeamOutlined />,
			},
			{
				key: "/inventory",
				title: "Inventory",
				icon: <GoldOutlined />,
			},
			{
				key: "/expenses",
				title: "Expenses",
				icon: <DollarCircleOutlined />,
			},
		];

		return (
			<TabBar
				activeKey={pathname}
				onChange={(value) => setRouteActive(value)}
			>
				{tabs.map((item) => (
					<TabBar.Item
						key={item.key}
						icon={item.icon}
						title={item.title}
					></TabBar.Item>
				))}
			</TabBar>
		);
	};

	return (
		<App>
			<Body>
				<Routes>
					<Route path="/" exact element={<MainPage />} />
					<Route path="/login" exact element={<LoginPage />} />
					<Route path="/item" exact element={<ItemPage />} />
					<Route path="/customer" exact element={<CustomerPage />} />
					<Route path="/order" exact element={<OrderPage />} />
					<Route path="/expenses" exact element={<ExpensesPage />} />
				</Routes>
			</Body>
			<Bottom>
				<NavBarComponent />
			</Bottom>
		</App>
	);
}

export default LayoutPageMobile;
