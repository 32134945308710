import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Button, Modal } from "antd";

import axios from "axios";

function ButtonDelete(props) {
	const [isLoading, setIsLoading] = useState(false);

	const handleDelete = () => {
		setIsLoading(true);
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				id: props.id,
			},
		};

		axios
			.delete(process.env.REACT_APP_DELETE_ITEM_URL, requestConfig)
			.then((res) => {
				console.log(`Item ${props.id} is successfully deleted.`);
				setIsLoading(false);
				props.onReload();
			})
			.catch((error) => {
				console.log(`Item ${props.id} failed to delete.`, error);
				setIsLoading(false);
			});
	};

	const confirm = () => {
		Modal.confirm({
			title: "Confirm",
			icon: <ExclamationCircleOutlined />,
			content: "Are you sure you want to delete this item?",
			okText: "OK",
			cancelText: "Cancel",
			onOk: handleDelete,
		});
	};

	return (
		<div>
			<Button loading={isLoading} danger onClick={confirm}>
				Delete
			</Button>
		</div>
	);
}

export default ButtonDelete;
