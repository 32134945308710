import React from "react";
import { Table, Space, Button } from "antd";

function OrderTableExpanded(record) {
	const columns = [
		{
			title: "Item",
			dataIndex: "item",
			key: "item",
			render: (text, record) => {
				return record.item.name
			}
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			key: "quantity",
		},
		{
			title: "Weight (KG)",
			dataIndex: "weight",
			key: "weight",
		},
		{
			title: "Raw Price (RM)",
			dataIndex: "raw_price",
			key: "raw_price",
		},
		{
			title: "Discount %",
			dataIndex: "discount",
			key: "discount",
		},
		{
			title: "Unit Price (RM)",
			dataIndex: "price",
			key: "price",
		},
	];

	return (
		<Table
			rowKey="item_id"
			columns={columns}
			dataSource={record.cart.cart_items}
			pagination={false}
		/>
	);
}

export default OrderTableExpanded;
