import React, { useState } from "react";
import { Card, Select, Modal, FloatButton, Tag, Empty } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import "moment-timezone";
import { Popup } from "antd-mobile";

import AddOrderModal from "../components/modals/AddOrderModal";
import SearchBarMobile from "../components/order/SearchBarMobile";
import ListLoading from "../components/common/ListLoading";
import ItemFilter from "../components/item/ItemFilter";

function OrderPageMobile(props) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState();

	const handleDownload = (orderId, invoiceNumber) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			responseType: "blob",
			params: {
				id: orderId,
			},
		};

		axios
			.get(process.env.REACT_APP_INVOICE_URL, requestConfig)
			.then((res) => {
				fileDownload(res.data, `${invoiceNumber}.pdf`);
			})
			.catch((error) => {
				console.log("fetch get all error - ", error);
			});
	};

	const handlePrint = (orderId) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: orderId,
		};

		axios
			.post(process.env.REACT_APP_PRINT_URL, requestBody, requestConfig)
			.then((res) => {
				console.log(`print orderId: ${orderId} submitted`);
			})
			.catch((error) => {
				console.log("submit pdf print error - ", error);
			});
	};

	const handleDelete = (orderId) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				id: orderId,
			},
		};

		axios
			.delete(process.env.REACT_APP_DELETE_ORDER_URL, requestConfig)
			.then((res) => {
				console.log(`Order ${orderId} is successfully deleted.`);
				props.onReload();
			})
			.catch((error) => {
				console.log(`Order ${props.id} failed to delete.`, error);
			});
	};

	const handleOrderStatusChange = (value, id) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: id,
			status: value,
		};

		axios
			.patch(
				process.env.REACT_APP_ORDER_UPDATE_STATUS_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {})
			.catch((error) => {
				console.log(`Order update error - ${id}`, error);
			});
	};

	const statusSelectorComponent = (item) => {
		return (
			<div>
				<div>Do you want to set this order status to </div>
				<Tag
					style={{
						fontFamily: "poppins-bold",
						margin: "0px",
					}}
					color={item.status !== "To Pay" ? "red" : "green"}
				>
					{item.status === "To Pay" ? "Paid" : "To Pay"}
				</Tag>
			</div>
		);
	};

	const handleOnSelect = (value, order) => {
		switch (value) {
			case "set_status":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: statusSelectorComponent(order),
					okText: "OK",
					cancelText: "Cancel",
					onOk: () =>
						handleOrderStatusChange(
							order.status === "To Pay" ? "Paid" : "To Pay"
						),
				});
			case "download":
				handleDownload(order.id, order.invoice_number);
				break;
			case "print":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: "Are you sure you want to print this order?",
					okText: "OK",
					cancelText: "Cancel",
					onOk: () => handlePrint(order.id),
				});
			case "delete":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: "Are you sure you want to delete this order?",
					okText: "OK",
					cancelText: "Cancel",
					onOk: () => handleDelete(order.id),
				});
			default:
				console.log("nothing selected");
				break;
		}
	};

	const ActionComponent = (order) => {
		return (
			<Select
				defaultValue="lucy"
				style={{
					width: 120,
				}}
				onSelect={(value) => handleOnSelect(value, order)}
				value={"Action"}
				options={[
					{
						value: "set_status",
						label: "Set Status",
					},
					{
						value: "download",
						label: "Download",
					},
					{
						value: "print",
						label: "Print",
					},
					{
						value: "delete",
						label: "Delete",
					},
				]}
			/>
		);
	};

	const ListItem = () =>
		props.orderData.map((order) => {
			return (
				<Card
					key={order.id}
					style={{
						marginTop: 16,
						boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								fontSize: "15px",
								fontFamily: "poppins-bold",
							}}
							onClick={() => {
								setIsPopupVisible(true);
								setSelectedOrder(order);
							}}
						>
							<div
								style={{
									color: "#5b5a60",
								}}
							>
								#{order.invoice_number}
							</div>
							<div
								style={{
									fontSize: "18px",
								}}
							>
								{order.customer_name}
							</div>
							<div
								style={{
									fontSize: "13px",
									color: "#7e85d3",
								}}
							>
								{moment(order.created_timestamp)
									.tz("Africa/Accra")
									.format("DD-MMM-YYYY, h:mm:ss a")}
							</div>
							<div
								style={{
									fontSize: "13px",
									color: "#97959d",
								}}
							>
								Phone: {order.phone_number}
							</div>
							<div>
								<Tag
									color="blue"
									style={{ fontFamily: "poppins-bold" }}
								>
									{order.is_delivery ? "Delivery" : "Pick up"}
								</Tag>
								<Tag
									style={{ fontFamily: "poppins-bold" }}
									color={
										order.status === "To Pay"
											? "red"
											: "green"
									}
								>
									{order.status}
								</Tag>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}
						>
							{ActionComponent(order)}
							<div
								style={{
									fontSize: "18px",
									fontFamily: "poppins-bold",
									color: "#55545a",
									textAlign: "end",
								}}
							>
								RM
								{(
									Math.round(order.total_price * 100) / 100
								).toFixed(2)}
							</div>
						</div>
					</div>
				</Card>
			);
		});

	const ListItemComponent = () => {
		if (props.isLoading) {
			return <ListLoading />;
		} else if (props.orderData.length > 0) {
			return ListItem();
		} else {
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
		}
	};

	const SummaryComponent = () => {
		return (
			<div style={{ marginTop: "10px" }}>
				<Tag color="blue" style={{ fontFamily: "poppins-bold" }}>
					Order: {props.tagOrderCount.total}
				</Tag>
				<Tag color="purple" style={{ fontFamily: "poppins-bold" }}>
					Week: {props.tagOrderCount.week}
				</Tag>
				<Tag color="cyan" style={{ fontFamily: "poppins-bold" }}>
					Month: {props.tagOrderCount.month}
				</Tag>
				<Tag color="red" style={{ fontFamily: "poppins-bold" }}>
					To Pay: {props.tagOrderCount.to_pay}
				</Tag>
			</div>
		);
	};

	const defaultStatusTagsData = [
		{ title: "All", checked: true, value: "all" },
		{ title: "To Pay", checked: false, value: "to_pay" },
		{ title: "Paid", checked: false, value: "paid" },
	];

	const defaultTimelineTagsData = [
		{ title: "All", checked: true, value: "all" },
		{ title: "Today", checked: false, value: "today" },
		{ title: "Week", checked: false, value: "week" },
		{ title: "Month", checked: false, value: "month" },
	];

	const orderItemsComponent = () => {
		if (isPopupVisible) {
			return selectedOrder.cart.cart_items.map((item) => {
				return (
					<div
						style={{
							padding: "10px",
							backgroundColor: "white",
							marginBottom: "10px",
							borderRadius: "5px",
							fontFamily: "Poppins",
						}}
					>
						<div
							style={{
								fontFamily: "Poppins-Bold",
								marginBlock: "2px",
							}}
						>
							{item.item.name}
						</div>
						<div>Quantity: {item.quantity}</div>
						<div>Weight: {item.weight}</div>
						<div>Unit Price: RM{item.price}</div>
					</div>
				);
			});
		}
	};

	return (
		<div>
			<SearchBarMobile />
			{SummaryComponent()}
			<ItemFilter
				title={"Timeline"}
				marginRight={0}
				defaultTagsData={defaultTimelineTagsData}
				onTagSelected={props.onTimelineTagSelected}
			/>
			<ItemFilter
				title={"Status"}
				marginRight={13}
				defaultTagsData={defaultStatusTagsData}
				onTagSelected={props.onStatusTagSelected}
			/>
			{ListItemComponent()}
			<FloatButton
				icon={<PlusOutlined />}
				type="primary"
				style={{ bottom: 70 }}
				onClick={() => setIsModalOpen(true)}
			/>
			<AddOrderModal
				isModalOpen={isModalOpen}
				onHandleCancel={() => setIsModalOpen(false)}
				onHandleClose={() => setIsModalOpen(false)}
				onReload={props.onReload}
			></AddOrderModal>
			<Popup
				visible={isPopupVisible}
				onMaskClick={() => {
					setIsPopupVisible(false);
				}}
				bodyStyle={{
					borderTopLeftRadius: "8px",
					borderTopRightRadius: "8px",
					minHeight: "40vh",
					backgroundColor: "#f1f4f9",
				}}
			>
				<div style={{ padding: "20px" }}>{orderItemsComponent()}</div>
			</Popup>
		</div>
	);
}

export default OrderPageMobile;
