import React, { useState } from "react";
import { Modal, Button, Form } from "antd";
import axios from "axios";

import ItemForm from "../forms/ItemForm";

function AddItemModal(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();

	const handleOK = () => {
		setIsLoading(true);

		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			name: form.getFieldValue("name"),
			description: form.getFieldValue("description"),
			price: form.getFieldValue("price"),
			weight: form.getFieldValue("weight"),
			available: form.getFieldValue("available") === "yes" ? true : false,
		};

		axios
			.post(
				process.env.REACT_APP_ADD_ITEM_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {
				props.onHandleClose();
				props.onReload();
			})
			.catch((error) => {
				console.log(`Item add error - `, error);
				setIsLoading(false);
			});
	};

	const emptyItem = {
		item: {
			name: "",
			description: "",
			price: "",
			weight: "",
			available: false,
		},
	};

	return (
		<Modal
			mask={false}
			title="Add New Item"
			open={props.isModalOpen}
			onOk={handleOK}
			onCancel={() => props.onHandleCancel()}
			width={1000}
			height={300}
			footer={[
				<Button onClick={() => props.onHandleCancel()}>Cancel</Button>,
				<Button
					key="submit"
					type="primary"
					loading={isLoading}
					onClick={handleOK}
				>
					Create
				</Button>,
			]}
			bodyStyle={{ overflowY: "scroll", height: 500 }}
		>
			<ItemForm item={emptyItem} form={form}></ItemForm>
		</Modal>
	);
}

export default AddItemModal;
