import React from "react";

import { Input } from "antd";

const { Search } = Input;

function SearchBarMobile() {
	const onSearch = (value) => console.log(value);

	return (
		<Search
			placeholder="Search for name, order, phone, etc"
			onSearch={onSearch}
			style={{ height: "30px" }}
		/>
	);
}

export default SearchBarMobile;
