import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Button, Modal } from "antd";

import axios from "axios";

function ButtonPrint(props) {
	const [isLoading, setIsLoading] = useState(false);

	const handlePrintInvoice = () => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: props.id,
		};

		axios
			.post(process.env.REACT_APP_PRINT_URL, requestBody, requestConfig)
			.then((res) => {
				console.log(`print orderId: ${props.id} submitted`);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log("submit pdf print error - ", error);
				setIsLoading(false);
			});
	};

	const confirm = () => {
		Modal.confirm({
			title: "Confirm",
			icon: <ExclamationCircleOutlined />,
			content: "Are you sure you want to print this order?",
			okText: "OK",
			cancelText: "Cancel",
			onOk: handlePrintInvoice,
		});
	};

	return (
		<div>
			<Button
				loading={isLoading}
				type="primary"
				style={{ background: "green", borderColor: "yellow" }}
				onClick={confirm}
			>
				Print
			</Button>
		</div>
	);
}

export default ButtonPrint;
