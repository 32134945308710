import React, { useState } from "react";
import { Button } from "antd";

import AddItemModal from "../modals/AddItemModal";

function AddItemButton(props) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<div>
			<Button
				type="primary"
				style={{
					backgroundColor: "#323C73",
					border: 0,
					marginBottom: "15px",
				}}
				onClick={() => setIsModalOpen(true)}
			>
				Add
			</Button>
			<AddItemModal
				isModalOpen={isModalOpen}
				onHandleCancel={() => setIsModalOpen(false)}
				onHandleClose={() => setIsModalOpen(false)}
				onReload={() => props.onHandleReload()}
			></AddItemModal>
		</div>
	);
}

export default AddItemButton;
