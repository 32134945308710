import React, { useState } from "react";
import axios from "axios";
import moment from "moment";

import { Modal, Select, Card, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ListLoading from "../components/common/ListLoading";
import SearchBarMobile from "../components/order/SearchBarMobile";

import EditCustomerModal from "../components/modals/EditCustomerModal";

function CustomerPageMobile(props) {
	const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false);
	const [customerEdit, setCustomerEdit] = useState();

	const handleDelete = (itemId) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				id: itemId,
			},
		};

		axios
			.delete(process.env.REACT_APP_CUSTOMER_URL, requestConfig)
			.then((res) => {
				console.log(`Item ${itemId} is successfully deleted.`);
				props.onReload();
			})
			.catch((error) => {
				console.log(`Item ${itemId} failed to delete.`, error);
			});
	};

	const handleOnSelect = (value, item) => {
		switch (value) {
			case "edit":
				setIsEditItemModalOpen(true);
				setCustomerEdit(item);
				break;
			case "delete":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: "Are you sure you want to delete this customer?",
					okText: "OK",
					cancelText: "Cancel",
					onOk: () => handleDelete(item.id),
				});
			default:
				console.log("nothing selected");
				break;
		}
	};

	const ActionComponent = (item) => {
		return (
			<Select
				style={{
					width: 100,
				}}
				onSelect={(value) => handleOnSelect(value, item)}
				value={"Action"}
				options={[
					{
						value: "edit",
						label: "Edit",
					},
					{
						value: "delete",
						label: "Delete",
					},
				]}
			/>
		);
	};

	const ListItem = () =>
		props.customerData.map((customer) => {
			return (
				<div>
					<Card
						key={customer.id}
						style={{
							marginTop: 16,
							boxShadow:
								"5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									width: "60%",
									fontSize: "15px",
									fontFamily: "poppins-bold",
								}}
							>
								<div
									style={{
										fontSize: "15px",
									}}
								>
									{customer.name}
								</div>
								<div
									style={{
										fontSize: "13px",
										color: "#7e85d3",
									}}
								>
									{customer.phone_number}
								</div>

								<div
									style={{
										fontSize: "13px",
										color: "#55545a",
									}}
								>
									{customer.email}
								</div>
								<div
									style={{
										fontSize: "10px",
										color: "green",
									}}
								>
									{moment(customer.created_timestamp).format(
										"DD-MMM-YYYY, h:mm:ss a"
									)}
								</div>
								<div
									style={{
										fontSize: "10px",
										color: "#55545a",
									}}
								>
									{customer.address}
								</div>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
							>
								{ActionComponent(customer)}
								<div></div>
							</div>
						</div>
					</Card>
				</div>
			);
		});

	const ListItemComponent = () => {
		if (props.customerData.length > 0) {
			return ListItem();
		} else {
			return <ListLoading />;
		}
	};

	const SummaryComponent = () => {
		return (
			<div style={{ marginTop: "10px" }}>
				<Tag color="blue" style={{ fontFamily: "poppins-bold" }}>
					Customers: {props.customerData.length}
				</Tag>
			</div>
		);
	};

	return (
		<div>
			<SearchBarMobile />
			{SummaryComponent()}
			{ListItemComponent()}
			<EditCustomerModal
				item={customerEdit}
				isModalOpen={isEditItemModalOpen}
				onHandleCancel={() => setIsEditItemModalOpen(false)}
				onHandleClose={() => setIsEditItemModalOpen(false)}
				onReload={props.onReload}
			></EditCustomerModal>
		</div>
	);
}

export default CustomerPageMobile;
