import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Form,
	Input,
	Button,
	Radio,
	Select,
	Cascader,
	DatePicker,
	InputNumber,
	TreeSelect,
	Switch,
	TimePicker,
	Space,
	Divider,
	Row,
	Col,
} from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { IsMobileWidth } from "../../utils/Utils";

const { Option } = Select;

function AddOrderForm(props) {
	const [componentSize, setComponentSize] = useState("default");
	const [recordType, setRecordType] = useState(1);
	const [itemData, setItemData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const onFormLayoutChange = ({ size }) => {
		setComponentSize(size);
	};

	const handleRecordTypeOnChange = (e) => {
		setRecordType(e.target.value);
		props.onRecordType(e.target.value);
	};

	const customerComponent = () => {
		if (recordType === 1) {
			return (
				<div>
					<Form.Item label="Customer Name" name="name">
						<Input />
					</Form.Item>
					<Form.Item label="Phone number" name="phone_number">
						<Input />
					</Form.Item>
					<Form.Item label="Address" name="address">
						<Input />
					</Form.Item>
				</div>
			);
		} else {
			return (
				<Form.Item
					name="customer_info"
					label="Customer"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select
						placeholder="Select an existing customer"
						allowClear
					>
						{customerData.map((customer) => (
							<Option value={customer.phone_number}>
								{customer.name} - {customer.phone_number}
							</Option>
						))}
					</Select>
				</Form.Item>
			);
		}
	};

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_ITEM_GET_ALL_URL)
			.then((res) => {
				setItemData(res.data);
			})
			.catch((error) => {
				console.log("Get all item error - ", error);
			});

		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		// Update the document title using the browser API
		axios
			.get(process.env.REACT_APP_CUSTOMERS_LATEST_URL, requestConfig)
			.then((res) => {
				setCustomerData(res.data);
			})
			.catch((error) => {
				console.log("fetch get all error - ", error);
			});
	}, []);

	const AddCartItemListComponent = () => {
		if (IsMobileWidth()) {
			return (
				<Form.List name="carts">
					{(fields, { add, remove }) => (
						<>
							{fields.map((field) => (
								<div>
									<Form.Item
										{...field}
										name={[field.name, "item_id"]}
									>
										<Select
											placeholder="Select item"
											style={{ width: 280 }}
										>
											{itemData.map((item) => (
												<Option value={item.id}>
													{item.name} - RM
													{item.price}
												</Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										{...field}
										name={[field.name, "quantity"]}
										rules={[
											{
												required: true,
												message: "Missing quantity",
											},
										]}
									>
										<InputNumber placeholder={"Quantity"} />
									</Form.Item>
									<Form.Item
										{...field}
										name={[field.name, "price"]}
										rules={[
											{
												required: true,
												message: "Missing price",
											},
										]}
									>
										<InputNumber
											placeholder="Final Price"
											style={{ width: "100%" }}
										/>
									</Form.Item>
									<MinusCircleOutlined
										onClick={() => remove(field.name)}
									/>
								</div>
							))}

							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									block
									icon={<PlusOutlined />}
								>
									Add items
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			);
		} else {
			return (
				<Form.List name="carts">
					{(fields, { add, remove }) => (
						<>
							{fields.map((field) => (
								<Row>
									<Col span={11}>
										<Form.Item
											{...field}
											name={[field.name, "item_id"]}
										>
											<Select
												placeholder="Select item"
												style={{ width: 300 }}
											>
												{itemData.map((item) => (
													<Option value={item.id}>
														{item.name} - RM
														{item.price}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											{...field}
											name={[field.name, "quantity"]}
											rules={[
												{
													required: true,
													message: "Missing quantity",
												},
											]}
										>
											<InputNumber
												placeholder={"Quantity"}
											/>
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											{...field}
											name={[field.name, "price"]}
											rules={[
												{
													required: true,
													message: "Missing price",
												},
											]}
										>
											<InputNumber
												placeholder="Final Price"
												style={{ width: "100%" }}
											/>
										</Form.Item>
									</Col>
									<Col span={3}>
										<MinusCircleOutlined
											onClick={() => remove(field.name)}
										/>
									</Col>
								</Row>
							))}

							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									block
									icon={<PlusOutlined />}
								>
									Add items
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			);
		}
	};

	return (
		<Form
			labelCol={{
				span: 4,
			}}
			wrapperCol={{
				span: 14,
			}}
			layout="horizontal"
			initialValues={{
				record_type: 1,
				size: componentSize,
			}}
			onValuesChange={onFormLayoutChange}
			size={componentSize}
			form={props.form}
		>
			<Divider orientation="left">Customer Details</Divider>
			<Form.Item va label="Type" name="record_type">
				<Radio.Group onChange={handleRecordTypeOnChange}>
					<Radio.Button value={1}>New</Radio.Button>
					<Radio.Button value={2}>Existing</Radio.Button>
				</Radio.Group>
			</Form.Item>
			{customerComponent()}
			<Form.Item label="Delivery" name="delivery">
				<Radio.Group>
					<Radio.Button value={1}>Yes</Radio.Button>
					<Radio.Button value={2}>No</Radio.Button>
				</Radio.Group>
			</Form.Item>
			<Divider orientation="left">Order Details</Divider>
			<div
				style={{
					display: "block",
					marginLeft: "auto",
					marginRight: "auto",
					width: "90%",
				}}
			>
				{AddCartItemListComponent()}
			</div>
		</Form>
	);
}

export default AddOrderForm;
