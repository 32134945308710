import React, { useState } from "react";
import { Form, Input, InputNumber, Divider, Radio } from "antd";

function CustomerForm(props) {
	const [componentSize, setComponentSize] = useState("default");
	const onFormLayoutChange = ({ size }) => {
		setComponentSize(size);
	};

	return (
		<Form
			labelCol={{
				span: 4,
			}}
			wrapperCol={{
				span: 14,
			}}
			layout="horizontal"
			initialValues={{
				size: componentSize,
				name: props.item.name,
				email: props.item.email,
				address: props.item.address,
				phone_number: props.item.phone_number,
			}}
			onValuesChange={onFormLayoutChange}
			size={componentSize}
			form={props.form}
		>
			<Divider orientation="left">Item Details</Divider>
			<Form.Item label="Name" name="name">
				<Input />
			</Form.Item>
			<Form.Item label="Email" name="email">
				<Input />
			</Form.Item>
			<Form.Item label="Address" name="address">
				<Input />
			</Form.Item>
			<Form.Item label="Phone Number" name="phone_number">
				<Input />
			</Form.Item>
		</Form>
	);
}

export default CustomerForm;
