import React, { useState } from "react";
import { Modal, Button, Form } from "antd";
import axios from "axios";

import AddOrderForm from "../forms/AddOrderForm";

function AddOrderModal(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [recordType, setRecordType] = useState(1);
	const [form] = Form.useForm();

	const addAdminOrder = (body) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		axios
			.post(process.env.REACT_APP_ORDER_ADD_URL, body, requestConfig)
			.then((res) => {
				props.onHandleClose();
				props.onReload();
			})
			.catch((error) => {
				console.log(`Item add error - `, error);
			});
	};

	const handleOk = () => {
		setIsLoading(true);

		let order = {
			name: "",
			is_new: recordType === 1,
			phone_number: "",
			address: "",
			is_delivery: false,
			order_list: [],
		};

		if (form.getFieldValue("record_type") === 1) {
			order.name = form.getFieldValue("name");
			order.phone_number = form.getFieldValue("phone_number");
			order.address = form.getFieldValue("address");
		} else {
			const customerPhoneNumber = form.getFieldValue("customer_info");
			order.phone_number = customerPhoneNumber;
		}
		order.is_delivery = form.getFieldValue("delivery") === 1;
		order.order_list = form.getFieldValue("carts");

		addAdminOrder(order);
	};

	return (
		<Modal
			mask={false}
			title="Add Order"
			open={props.isModalOpen}
			onCancel={() => props.onHandleCancel()}
			width={1000}
			height={300}
			footer={[
				<Button onClick={() => props.onHandleCancel()}>Cancel</Button>,
				<Button
					key="submit"
					type="primary"
					loading={isLoading}
					onClick={handleOk}
				>
					Create
				</Button>,
			]}
			bodyStyle={{ overflowY: "scroll", height: 500 }}
		>
			<AddOrderForm
				form={form}
				onRecordType={(value) => setRecordType(value)}
			></AddOrderForm>
		</Modal>
	);
}

export default AddOrderModal;
