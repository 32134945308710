import React from "react";
import { Card, Select, Modal, FloatButton, Tag, Empty } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import "moment-timezone";

import SearchBarMobile from "../../components/order/SearchBarMobile";
import ListLoading from "../../components/common/ListLoading";
import ItemFilter from "../../components/item/ItemFilter";

import {
	ExpensesCategoryEnum,
	ExpensesInnerCategoryEnum,
	ExpensesStatusEnum,
} from "../../constants/Expenses";

function ExpensesPageMobile(props) {
	const SummaryComponent = () => {
		return (
			<div style={{ marginTop: "10px" }}>
				<Tag color="red" style={{ fontFamily: "poppins-bold" }}>
					Total: RM{props.tagExpensesCount.total}
				</Tag>
				<Tag color="purple" style={{ fontFamily: "poppins-bold" }}>
					Week: RM{props.tagExpensesCount.week}
				</Tag>
				<Tag color="cyan" style={{ fontFamily: "poppins-bold" }}>
					Month: RM{props.tagExpensesCount.month}
				</Tag>
			</div>
		);
	};

	const handleDelete = (itemId) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				id: itemId,
			},
		};

		axios
			.delete(process.env.REACT_APP_EXPENSES_URL, requestConfig)
			.then((res) => {
				console.log(`Expenses ${itemId} is successfully deleted.`);
				props.onReload();
			})
			.catch((error) => {
				console.log(`Expenses ${itemId} failed to delete.`, error);
			});
	};

	const handleOrderStatusChange = (value, id) => {
		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const requestBody = {
			id: id,
			status: value,
		};

		axios
			.patch(
				process.env.REACT_APP_EXPENSES_STATUS_URL,
				requestBody,
				requestConfig
			)
			.then((res) => {})
			.catch((error) => {
				console.log(`Order update error - ${id}`, error);
			});
	};

	// const statusSelectorComponent = (item) => {
	// 	return (
	// 		<div>
	// 			<div>Do you want to set this order status to </div>
	// 			<Tag
	// 				style={{
	// 					fontFamily: "poppins-bold",
	// 					margin: "0px",
	// 				}}
	// 				color={item.status !== "To Pay" ? "red" : "green"}
	// 			>
	// 				{item.status === "To Pay" ? "Paid" : "To Pay"}
	// 			</Tag>
	// 		</div>
	// 	);
	// };

	const handleOnSelect = (value, item) => {
		switch (value) {
			// case "update_status":
			// 	return Modal.confirm({
			// 		title: "Confirm",
			// 		icon: <ExclamationCircleOutlined />,
			// 		content: statusSelectorComponent(order),
			// 		okText: "OK",
			// 		cancelText: "Cancel",
			// 		onOk: () =>
			// 			handleOrderStatusChange(
			// 				order.status === "To Pay" ? "Paid" : "To Pay"
			// 			),
			// 	});
			case "delete":
				return Modal.confirm({
					title: "Confirm",
					icon: <ExclamationCircleOutlined />,
					content: "Are you sure you want to delete this expenses?",
					okText: "OK",
					cancelText: "Cancel",
					onOk: () => handleDelete(item.id),
				});
			default:
				console.log("nothing selected");
				break;
		}
	};

	const ActionComponent = (item) => {
		return (
			<Select
				defaultValue="lucy"
				style={{
					width: 120,
				}}
				onSelect={(value) => handleOnSelect(value, item)}
				value={"Action"}
				options={[
					// {
					// 	value: "update_status",
					// 	label: "Update Status",
					// },
					{
						value: "delete",
						label: "Delete",
					},
				]}
			/>
		);
	};

	const ListItem = () =>
		props.expensesData.map((item) => {
			return (
				<Card
					key={item.id}
					style={{
						marginTop: 16,
						boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								fontSize: "15px",
								fontFamily: "poppins-bold",
							}}
						>
							<div
								style={{
									fontSize: "15px",
								}}
							>
								{
									ExpensesInnerCategoryEnum[item.category][
										item.inner_category
									]
								}
							</div>
							<div
								style={{
									fontSize: "12px",
									color: "#7e85d3",
								}}
							>
								{moment(item.created_timestamp)
									.tz("Africa/Accra")
									.format("DD-MMM-YYYY, h:mm:ss a")}
							</div>
							<div
								style={{
									fontSize: "10px",
									color: "#5b5a60",
								}}
							>
								{item.description}
							</div>
							<div>
								<Tag
									style={{
										fontFamily: "poppins-bold",
									}}
									color={"red"}
								>
									{ExpensesCategoryEnum[item.category]}
								</Tag>
								<Tag
									style={{
										fontFamily: "poppins-bold",
										fontSize: "10px",
									}}
									color={"cyan"}
								>
									{ExpensesStatusEnum[item.status]}
								</Tag>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}
						>
							{ActionComponent(item)}
							<div
								style={{
									fontSize: "18px",
									fontFamily: "poppins-bold",
									color: "#55545a",
									textAlign: "end",
								}}
							>
								RM
								{(
									Math.round(
										item.price * item.quantity * 100
									) / 100
								).toFixed(2)}
							</div>
						</div>
					</div>
				</Card>
			);
		});

	const ListItemComponent = () => {
		if (props.isLoading) {
			return <ListLoading />;
		} else if (props.expensesData.length > 0) {
			return ListItem();
		} else {
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
		}
	};

	const defaultTimelineTagsData = [
		{ title: "All", checked: true, value: "all" },
		{ title: "Today", checked: false, value: "today" },
		{ title: "Week", checked: false, value: "week" },
		{ title: "Month", checked: false, value: "month" },
	];

	console.log(props.expensesData);

	return (
		<div>
			<SearchBarMobile />
			{SummaryComponent()}
			<ItemFilter
				title={"Timeline"}
				marginRight={0}
				defaultTagsData={defaultTimelineTagsData}
				onTagSelected={props.onTimelineTagSelected}
			/>
			{ListItemComponent()}
			<FloatButton
				icon={<PlusOutlined />}
				type="primary"
				style={{ bottom: 70 }}
				onClick={() => props.onMobileAddExpenses()}
			/>
		</div>
	);
}

export default ExpensesPageMobile;
