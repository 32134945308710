import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { NavBar, Button } from "antd-mobile";
import { Form } from "antd";
import AddExpensesForm from "../../components/expenses/AddExpensesForm";

const Top = styled.div`
	flex: 0;
	border-bottom: solid 1px var(--adm-color-border);
`;

function AddExpensesPageMobile(props) {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);

	const handleFormSubmit = () => {
		setIsLoading(true);

		const token = window.localStorage.getItem("token");
		const requestConfig = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const body = {
			name: "",
			category: parseInt(form.getFieldValue("category")),
			inner_category: parseInt(form.getFieldValue("inner_category")),
			description: form.getFieldValue("description"),
			quantity: parseInt(form.getFieldValue("quantity")),
			price: parseFloat(form.getFieldValue("price")),
		};

		axios
			.post(process.env.REACT_APP_EXPENSES_URL, body, requestConfig)
			.then((res) => {
				props.onBack();
				props.onReload();
			})
			.catch((error) => {
				setIsLoading(false);
				console.log(`Item add error - `, error);
			});
	};

	return (
		<div>
			<Top>
				<NavBar onBack={() => props.onBack()}>Add Expenses</NavBar>
			</Top>
			<div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
				<div>
					<AddExpensesForm form={form}></AddExpensesForm>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							loading={isLoading}
							size="large"
							style={{ width: "70%" }}
							color="primary"
							onClick={() => handleFormSubmit()}
						>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddExpensesPageMobile;
