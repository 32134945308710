import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useState } from "react";
import axios from "axios";

function PictureWall(props) {
	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = () => resolve(reader.result);

			reader.onerror = (error) => reject(error);
		});

	const token = window.localStorage.getItem("token");

	const itemImages = props.item.images.map((image) => {
		return {
			uid: image.id,
			name: image.name,
			status: "done",
			url: image.img_url,
		};
	});

	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState(itemImages);

	const handleCancel = () => setPreviewVisible(false);

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};

	const handleChange = (info) => {
		let fileList = info.fileList;
		if (info.file.status === "done") {
			fileList = info.fileList.map((file) =>
				file.uid === info.file.uid
					? { ...file, uid: info.file.response }
					: file
			);
		}
		setFileList(fileList);
	};

	const handleRemove = (file) => {
		const { confirm } = Modal;
		return new Promise((resolve, reject) => {
			confirm({
				title: "Are you sure you want to Delete ?",
				onOk: () => {
					resolve(true);
					const requestConfig = {
						headers: {
							Authorization: `Bearer ${token}`,
						},
						params: {
							id: file.uid,
						},
					};

					axios
						.delete(
							process.env.REACT_APP_DELETE_IMAGE_URL,
							requestConfig
						)
						.then((res) => {
							console.log(
								`Image ${file.uid} is successfully deleted.`
							);
						})
						.catch((error) => {
							console.log(
								`Image ${file.uid} failed to delete.`,
								error
							);
						});
				},
				onCancel: () => {
					reject(true);
				},
			});
		});
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</div>
	);

	return (
		<div>
			<Upload
				data={(file) => {
					return { file_name: file.name, item_id: props.item.id };
				}}
				action={process.env.REACT_APP_ADD_IMAGE_URL}
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
				headers={{ Authorization: `Bearer ${token}` }}
				onRemove={handleRemove}
			>
				{fileList.length >= 10 ? null : uploadButton}
			</Upload>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img
					alt="example"
					style={{
						width: "100%",
					}}
					src={previewImage}
				/>
			</Modal>
		</div>
	);
}

export default PictureWall;
