import moment from "moment";

export const IsMobileWidth = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return width < 500;
};

export const IsWithinToday = (inputDateTimeString) => {
	let inputDateTime = moment(inputDateTimeString).toDate();
	let today = new Date();
	let deadline = new Date().setDate(today.getDate() - 1);
	return inputDateTime > deadline;
};

export const IsWithinThisWeek = (inputDateTimeString) => {
	let inputDateTime = moment(inputDateTimeString).toDate();
	let today = new Date();
	let deadline = new Date().setDate(today.getDate() - 7);
	return inputDateTime > deadline;
};

export const IsWithinThisMonth = (inputDateTimeString) => {
	let inputDateTime = moment(inputDateTimeString).toDate();
	let today = new Date();
	let deadline = new Date().setDate(today.getDate() - 30);
	return inputDateTime > deadline;
};
