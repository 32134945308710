import React from "react";

function MainPage() {
	return (
		<div>
			<p></p>
		</div>
	);
}

export default MainPage;
