import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import styled from "styled-components";

import LandingPage from "./pages/LandingPage";

ReactDOM.render(
	<BrowserRouter>
		<LandingPage></LandingPage>
	</BrowserRouter>,
	document.getElementById("root")
);
