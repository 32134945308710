import React from "react";

import EditItemButton from "../item/EditItemButton";
import ButtonDelete from "./ButtonDelete";
import { Table, Space, Button } from "antd";

function ItemTable(props) {
	const handleReload = () => {
		props.onReload();
	};

	const columns = [
		{ title: "ID", dataIndex: "id", key: "id", width: "10%" },
		{ title: "Name", dataIndex: "name", key: "name" },
		{ title: "Weight", dataIndex: "weight", key: "weight" },
		{ title: "Description", dataIndex: "description", key: "description" },
		{ title: "Price", dataIndex: "price", key: "price" },
		{ title: "Rank", dataIndex: "rank", key: "rank" },
		{
			title: "Available",
			dataIndex: "available",
			key: "available",
			render: (text, record, index) => {
				return <a>{text ? "Yes" : "No"}</a>;
			},
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (text, record, index) => (
				<Space size="middle">
					<EditItemButton
						item={record}
						onReload={handleReload}
					></EditItemButton>
					<ButtonDelete
						id={record.id}
						onReload={() => props.onReload()}
					/>
				</Space>
			),
		},
	];

	return (
		<Table
			rowKey="order_id"
			columns={columns}
			dataSource={props.items}
			scroll={{ x: 300 }}
		/>
	);
}

export default ItemTable;
