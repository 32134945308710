import React, { useState } from "react";
import { Button } from "antd";

import EditCustomerModal from "../modals/EditCustomerModal";

function EditCustomerButton(props) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	return (
		<div>
			<Button
				type="primary"
				style={{ backgroundColor: "#323C73", border: 0 }}
				onClick={() => setIsModalOpen(true)}
			>
				Edit
			</Button>
			<EditCustomerModal
				item={props.item}
				isModalOpen={isModalOpen}
				onHandleCancel={() => setIsModalOpen(false)}
				onHandleClose={() => setIsModalOpen(false)}
				onReload={() => props.onHandleReload()}
			></EditCustomerModal>
		</div>
	);
}

export default EditCustomerButton;
