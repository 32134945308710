import React, { useState } from "react";
import { Form, Input, InputNumber, Divider, Radio } from "antd";

function ItemForm(props) {
	const [componentSize, setComponentSize] = useState("default");
	const onFormLayoutChange = ({ size }) => {
		setComponentSize(size);
	};

	return (
		<Form
			labelCol={{
				span: 4,
			}}
			wrapperCol={{
				span: 14,
			}}
			layout="horizontal"
			initialValues={{
				size: componentSize,
				name: props.item.name,
				description: props.item.description,
				price: props.item.price,
				weight: props.item.weight,
				available: props.item.available === true ? "yes" : "no",
			}}
			onValuesChange={onFormLayoutChange}
			size={componentSize}
			form={props.form}
		>
			<Divider orientation="left">Item Details</Divider>
			<Form.Item label="Name" name="name">
				<Input />
			</Form.Item>
			<Form.Item label="Description" name="description">
				<Input />
			</Form.Item>
			<Form.Item label="Price" name="price">
				<InputNumber />
			</Form.Item>
			<Form.Item label="Weight" name="weight">
				<InputNumber />
			</Form.Item>
			<Form.Item label="Available?" name="available">
				<Radio.Group>
					<Radio.Button value="yes">Yes</Radio.Button>
					<Radio.Button value="no">No</Radio.Button>
				</Radio.Group>
			</Form.Item>
		</Form>
	);
}

export default ItemForm;
