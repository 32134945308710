import React, { useState } from "react";
import { Button } from "antd";

import AddOrderModal from "../modals/AddOrderModal";

function AddOrderButton() {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<div>
			<Button
				type="primary"
				style={{ backgroundColor: "#323C73", border: 0 }}
				onClick={() => setIsModalOpen(true)}
			>
				Add Order
			</Button>
			<AddOrderModal
				isModalOpen={isModalOpen}
				onHandleCancel={() => setIsModalOpen(false)}
				onHandleClose={() => setIsModalOpen(false)}
			></AddOrderModal>
		</div>
	);
}

export default AddOrderButton;
